import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Nl2br from 'vue-nl2br'
import $ from 'jquery'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from '../public/assets/toaster/toast_presets.json'
import VueCompositionAPI from '@vue/composition-api'
import VueCookie from 'vue-cookie'

Vue.component('nl2br', Nl2br)

Vue.config.productionTip = false

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'top-right',
  containerOffset: '45px'
})
Vue.use(VueCompositionAPI)
Vue.use(VueCookie)

Vue.use(require('vue-moment'))

new Vue({
  data: function () {
    return {

      wallet_address: null,
      loading: true,
      loading_gif: false,
      show_alert: false,
      message: 'Alert'

    }
  },
  mounted: function () {
    const component = this

    setTimeout(function () { component.loading = false }, 1000)
  },
  router,
  render: h => h(App)
}).$mount('#app')
