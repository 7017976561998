<template>

  <div class="footer">
    <span>TRIBE MARKET 2022</span>
  </div>

</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>