<template>
<div>
  <div v-show="loading == true" class="loader">
    <img src="/assets/img/plaque.png" width="400" />
  </div>
  <div v-show="loading_gif == true" class="loader">
    <div id="loading" >
		<span class="title">Please wait...</span>

		<svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
			<polygon fill="none" stroke="#333333" stroke-width="1" points="16,0 32,32 0,32"></polygon>
		</svg>
	</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Loader',
  props : {
    loading : {
      type: Boolean,
      default: true,
    },
    loading_gif : {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>

</style>