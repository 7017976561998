<template>
  <div id="app">
    <div id="wrapper">
      <div id="page" class="clearfix">
        <Alert :show="$root.show_alert" :message="$root.message"></Alert>
        <Loader
          :loading="$root.loading"
          :loading_gif="$root.loading_gif"
        ></Loader>
        <Header :cat="cat"></Header>
        <router-view />
        <Footer></Footer>
      </div>
      <!-- /#page -->
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Loader from "./components/Loader.vue";
import Alert from "./components/Alert.vue";

export default {
  name: "App",
  components: {
    Loader,
    Header,
    Footer,
    Alert,
  },
  data: function () {
    return {
      cat: "raffles",
    };
  },
  computed: {},
  mounted: function () {
    var url = window.location.pathname;

    if (url == "/shop" || url == "/shop/AquaPipe" || url == "/shop/SeaMT") {
      this.cat = "shop";
    }
    if (url == "/merch") {
      this.cat = "merch";
    } else {
      this.cat = "raffles";
    }
  },
};
</script>

<style></style>
