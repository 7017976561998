import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueRouter)
Vue.use(VueCompositionAPI)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Raffles.vue')
  },
  {
    path: '/raffle/:id',
    name: 'Raffle',
    component: () => import('../views/Raffle.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/merch',
    name: 'Merch',
    component: () => import('../views/Merch.vue')
  },
  {
    path: '/shop/seamt',
    name: 'SeaMT',
    component: () => import('../views/SeaMT.vue')
  },
  {
    path: '/shop/discountcodes',
    name: 'DiscountCodes',
    component: () => import('../views/DiscountCodes.vue')
  },
  {
    path: '/shop/aquapipe',
    name: 'AquaPipe',
    component: () => import('../views/AquaPipe.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
