<template>
  <div class="header">
    <div
      class="cct-modal-background"
      v-show="display_modal === true && wallet_address === null"
    >
      <div class="cct-modal-border">
        <div class="cct-modal">
          <div class="cct-modal-close" @click="display_modal = false">X</div>
          <div class="cct-modal-title">Connect wallet</div>
          <div
            class="cct-modal-button button"
            @click="connect_wallet('phantom')"
          >
            <div class="button-left-border"></div>
            <div class="button-right-border"></div>
            <div class="button-content cct-wallet-button-content">
              <span class="button-img cct_wallet_img"
                ><img src="/assets/images/wallet/phantom.jpg"
              /></span>
              <span>Phantom</span>
            </div>
          </div>
          <div
            class="cct-modal-button button"
            @click="connect_wallet('solflare')"
          >
            <div class="button-left-border"></div>
            <div class="button-right-border"></div>
            <div class="button-content cct-wallet-button-content">
              <span class="button-img cct_wallet_img"
                ><img src="/assets/images/wallet/solflare.svg"
              /></span>
              <span>Solflare</span>
            </div>
          </div>
          <div
            class="cct-modal-button button"
            @click="connect_wallet('sollet')"
          >
            <div class="button-left-border"></div>
            <div class="button-right-border"></div>
            <div class="button-content cct-wallet-button-content">
              <span class="button-img cct_wallet_img"
                ><img src="/assets/images/wallet/sollet.jpg"
              /></span>
              <span>Sollet</span>
            </div>
          </div>
          <div class="cct-modal-button button" @click="connect_wallet('slope')">
            <div class="button-left-border"></div>
            <div class="button-right-border"></div>
            <div class="button-content cct-wallet-button-content">
              <span class="button-img cct_wallet_img"
                ><img src="/assets/images/wallet/slope.png"
              /></span>
              <span>Slope</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-first-info">
      <a href="/">
        <img src="/assets/img/logo.png" />
      </a>
      <div class="header-wallet-container">
        <div
          class="header-wallet button"
          @click="
            wallet_address !== null
              ? wallet_address === null
              : (display_modal = true)
          "
        >
          <div class="button-left-border"></div>
          <div class="button-right-border"></div>
          <div class="button-content">
            <span class="button-img"
              ><img src="/assets/img/wallet_icon.png"
            /></span>
            <span v-if="wallet_address === null">connect wallet</span>
            <span v-if="wallet_address !== null"
              >{{ wallet_address.substr(0, 8) }}...</span
            >
            <span></span>
          </div>
        </div>
        <div v-if="token_count">
          <span>Balance: </span><span>{{ token_count }} $aqua</span>
        </div>
      </div>
    </div>
    <img class="header-banner" src="/assets/img/plaque.png" />

    <div class="header-cat">
      <a href="/merch" :class="{ cat: true, active: cat == 'merch' }">
        <span> Merch </span>
        <div class="cat-line"></div>
      </a>
      <a href="/" :class="{ cat: true, active: cat == 'raffles' }">
        <span> raffles </span>
        <div class="cat-line"></div>
      </a>
      <a href="/shop" :class="{ cat: true, active: cat == 'shop' }">
        <span> Market </span>
        <div class="cat-line"></div>
      </a>
    </div>
  </div>
</template>

<script>
import { connexion_with_sollet, connexion_with_slope } from "@/libs/wallet";
import { cct_alert } from "@/libs/CCT_alert";

import {
  getTokenAccountForTokenAndOwner,
  getTokenBalanceForTokenAndOwner,
} from "@/libs/solanaConnection";

export default {
  name: "Header",
  props: {
    cat: {
      type: String,
      default: "raffles",
    },
  },
  data: function () {
    return {
      display_modal: false,
      wallet_address: null,
      token_count: null,
    };
  },
  created() {
    var component = this;

    component.$root.$on("display_connection_modal", function () {
      component.display_modal = true;
    });
  },
  methods: {
    connect_wallet: async function (wallet_name) {
      var component_vue = this;

      if (wallet_name == "phantom" && getProviderPhantom() == undefined) return;
      else if (wallet_name == "solflare" && getProviderSolflare() == undefined)
        return;
      else if (wallet_name == "sollet") {
        var sollet_wallet = await connexion_with_sollet();

        if (sollet_wallet != null && sollet_wallet.connected === false) return;

        return component_vue.connexion_wallet_connect(
          sollet_wallet.publicKey.toString()
        );
      } else if (wallet_name == "slope") {
        var slope_wallet = await connexion_with_slope();

        if (slope_wallet === false) {
          window.open("https://slope.finance", "_blank");
          return;
        } else {
          return component_vue.connexion_wallet_connect(
            slope_wallet.publicKey.toString()
          );
        }
      }

      var provider_name = "";

      if (wallet_name == "phantom") provider_name = window.solana;
      else if (wallet_name == "solflare") provider_name = window.solflare;

      provider_name.connect();

      provider_name.on("connect", () => {
        var wallet_address = "";

        if (wallet_name == "phantom")
          wallet_address = provider_name.publicKey.toBase58();
        else if (wallet_name == "solflare" || wallet_name == "sollet")
          wallet_address = provider_name.publicKey.toString();

        return component_vue.connexion_wallet_connect(wallet_address);
      });
    },

    connexion_wallet_connect: async function (wallet_address) {
      this.wallet_address = wallet_address;
      this.$root.wallet_address = wallet_address;
      this.$root.$emit("wallet_connexion_success");

      // const token_address = 'FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk';
      const token_address = "Cqt1MDAvMAoLd9KCikCpGiBGvkEv6FLoKz2ndyqUFDDG";

      var tokenAccount = await getTokenAccountForTokenAndOwner(
        token_address,
        wallet_address
      );

      var balance = await getTokenBalanceForTokenAndOwner(
        token_address,
        tokenAccount
      );

      this.token_count = balance;
    },
  },
};

const getProviderPhantom = () => {
  if ("solana" in window) {
    const provider = window.solana;

    if (provider.isPhantom) {
      return provider;
    }
  }

  window.open("https://phantom.app/", "_blank");
};

const isSolflareInstalled = window.solflare && window.solflare.isSolflare;

const getProviderSolflare = () => {
  if ("solflare" in window) {
    const provider = window.solflare;

    if (provider.isSolflare) {
      return provider;
    }
  }
  window.open("https://solflare.com", "_blank");
};
</script>

<style scoped></style>
