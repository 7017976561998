<template>

  <div class="alert-background" id="cct_alert">
    <div class="alert-container" id="cct_alert_container">

      <p></p>

    </div>
  </div>

</template>

<script>
export default {
  name: 'Alert',
}
</script>

<style scoped>

</style>